.dropdown-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    left: 1008px;
    top: 158px;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15), 0px 2px 8px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.custom-dropdown {
    border: none !important;
}
