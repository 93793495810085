
.choice {
  width: 100%;

  input {
    width: auto;
    padding: 0px;
    margin-left: 0px;
    display: none;

    &:checked + label {
      border: 1px solid #359B05;
      
      > .choice-input-custom {
        > .choice-input-unchecked {
          display: none;
        }
        > .choice-input-checked {
          display: flex;
        }
      }
    }
  }

  label {
    display: flex;
    width: 100%;
    gap: 16px;
    border: 1px solid #E0E0E0;
    padding: 16px;
    border-radius: 16px;
    cursor: pointer;
    overflow: hidden;
    align-items: center;
    .choice-input-custom {
      flex-shrink: 0;
      .choice-input-checked {
        display: none;
      }
    }

    .choice-label {
      font-weight: 600!important;
      font-size: 16px;
    }

  }

  .choice-input-custom {
    .choice-input-unchecked {
      width: 24px;
      height: 24px;
      border: 1px solid #E0E0E0;
      border-radius: 100%;
    }
  }

}