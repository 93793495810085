.mosleme-table .ant-spin-nested-loading>div>.ant-spin {
    @apply !top-[55px] !max-h-none;
    height: calc(100% - 55px);
}

.mosleme-table .ant-spin-container.ant-spin-blur {
    @apply opacity-100;
}

.mosleme-table .ant-spin-container.ant-spin-blur::after {
    @apply opacity-0;
}

.mosleme-table .ant-spin-container.ant-spin-blur .ant-table-tbody {
    @apply opacity-40;
}

.mosleme-table .ant-checkbox .ant-checkbox-input {
    z-index: 0 !important;
}