.places-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  width: 300px;
}

.map-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 20px;
  border-radius: 16px;
}

.combobox-input {
  width: 100%;
  padding: 0.5rem;
}

.gmnoprint {
  display: none;
}
.gm-control-active {
  display: none;
}
.gm-fullscreen-control {
  display: none;
}
a {
  display: none;
}

div {
  img[alt~="Google"]{

    display: none;


  }
}