.active-menu {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
   
        background: linear-gradient(204.44deg, #F90601 -18.49%, #8A1212 84.72%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
}

.input-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
    color: #4F4F4F;
}
