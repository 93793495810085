.publish-more {
    position: relative !important;
}

.publish-more:hover .publish-more-content {
    display: block !important;
}

.publish-more-content {
    position: absolute !important;
    display: none;
}