.mosleme-pagination .ant-pagination {
  display: flex;
}

.mosleme-pagination .ant-pagination  .ant-pagination-options {
  order: -1;
  margin-right: 1rem;
}

.mosleme-pagination .ant-pagination  .ant-pagination-options .ant-select-selector {
  border: 0.5px solid #E0E0E0;
  border-radius: 8px;
  font-size: 12px;
}

.mosleme-pagination .ant-select-dropdown .ant-select-item-option-content {
  font-size: 12px;
} 

.mosleme-pagination .ant-pagination-item-active {
  border: none;
  background: linear-gradient(198.43deg, #ffbfab 29.17%, #f99576 91.67%);
}

.mosleme-pagination .ant-pagination-item {
  font-size: 12px;
  padding-left: 9px;
  padding-right: 9px;
  padding-top: 2px;
  padding-bottom: 3px;
  border: 0.5px solid #E0E0E0;
  /* padding: 0px; */
}

.mosleme-pagination .ant-pagination-item {
  padding: 0px;
}

.mosleme-pagination .ant-pagination-item-active a {
  color: black;
  padding: 0px;
}
.mosleme-pagination .ant-pagination-item-active a:hover {
  color: black!important;
}
.mosleme-pagination .ant-select-selector {
  border-color: rgb(190, 190, 190);
}
.mosleme-pagination .ant-select-selector:hover {
  border-color: rgb(190, 190, 190) !important;
}
.mosleme-pagination .ant-select-selector:active {
  border-color: rgb(190, 190, 190) !important;
}

.mosleme-table .ant-table-row-selected td {
  background-color: #fbc8c8 !important;
  color: gray;
}

.mosleme-pagination .ant-pagination-next .ant-pagination-item-link, .mosleme-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}