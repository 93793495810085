@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "GeneralSans";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Regular.ttf)
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Semibold.ttf)
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSans";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Bold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* GeneralSansRegular */

@font-face {
  font-family: "GeneralSansRegular";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Regular.ttf)
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSansRegular";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSansRegular";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Semibold.ttf)
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSansRegular";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Bold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSansMedium";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSansSemibold";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Semibold.ttf)
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSansBold";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Bold.ttf) format("truetype");
  font-weight: 800;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  height: fit-content;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  top: unset;
  right: unset;
  bottom: unset;
  left: unset;
  position: unset;
  color: #101010;
  overflow: auto;
  background-color: white;
  font-family: "GeneralSansRegular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  scrollbar-color: transparent transparent;
}

h1,
h2,
h3,
h4,
h5,
p,
div,
label,
span,
input,
button {
  font-family: "GeneralSansRegular";
}

/* body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-select-selector {
  /* padding: 0!important; */
}

/* .slick-track,
.slick-slide {
  width: auto !important;
} */

.lg-yellow {
  background: linear-gradient(
    200.57deg,
    rgba(249, 242, 24, 0.2) 3.37%,
    rgba(226, 162, 70, 0.2) 86.36%
  ) !important;
}

.ant-upload-select {
  @apply !border-none;
}

.ant-upload-list-picture-card {
  @apply flex flex-row-reverse;
}

.makeStyles-modal-116 {
  z-index: 1000 !important;
}

.ln-random {
  background: rgba(0, 0, 0, 0.6);
}

.ln-blue {
  background: linear-gradient(204.44deg, #4590f4 -18.49%, #1361c9 84.72%);
  border-radius: 8px;
}

.ant-image-preview-operations {
  @apply py-2;
}

/* .ant-image-preview-operations-operation-close {
  @apply absolute left-0
}

.ant-image-preview-operations-operation-rotateRight {
  orientation: portrait;
  @apply absolute right-0
}

.ant-image-preview-operations-operation-zoomIn {
  @apply absolute right-14 rotate-0
}

.ant-image-preview-operations-operation-zoomOut {
  @apply absolute right-28
} */

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn-modal {
  @apply flex justify-center items-center text-white text-xl drop-shadow cursor-pointer noselect;
}

.selectBoi > div > span {
  color: rgb(104, 0, 179);
  font-size: 19px !important;
  font-weight: bolder;
  right: 6px;
  margin-top: -8px;
}
.spiner-loading {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 900px;
  align-content: center;
}
/* .ant-select-selector{
  height: ;
} */

/* CUSTOM CHART CSS */
.apexcharts-canvas .apexcharts-tooltip.apexcharts-theme-light {
  border: none !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08),
    0px 10px 16px -6px rgba(0, 0, 0, 0.04),
    0px 4px 80px -4px rgba(0, 0, 0, 0.04) !important;
}
.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-text {
  font-family: "GeneralSansRegular" !important;
}
.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-title {
  background-color: #fff !important;
  border: none !important;
  padding-top: 6 !important;
  padding-bottom: 0 !important;
  padding-left: 8 !important;
  padding-right: 8 !important;
  color: #888888;
  margin-bottom: 0px;
}

.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-marker {
  display: none !important;
}

.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-series-group {
  padding: 0 6px !important;
}

.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-text-y-label {
  display: none;
}
.apexcharts-canvas
  .apexcharts-tooltip.apexcharts-theme-light
  .apexcharts-tooltip-text-y-value {
  margin: 0px;
  font-weight: 800;
  /* font-family: "GeneralSansBold"!important; */
}

.apexcharts-xaxistooltip-bottom {
  display: none !important;
}

/* ANT TABLE */
.ant-table-content {
  overflow: visible !important;
}
.ant-table-thead .ant-table-cell.ant-table-row-expand-icon-cell {
  @apply !bg-white !py-4;
  border-bottom: 1px solid #e0e0e0 !important;
}

th.ant-table-cell {
  @apply !px-2 !py-4;
}

td.ant-table-cell {
  @apply !px-2 !py-3;
}

.ant-table-cell.ant-table-selection-column {
  @apply !px-4;
}

.ant-table-row-expand-icon-cell {
  width: 64px !important;
}

.ant-table-expanded-row .ant-table-cell {
  @apply !py-0 !pl-[72px];
}

/* In your Tailwind CSS file or style tag */
.costumScrollbar::-webkit-scrollbar:horizontal {
  display: none;
}
/* Scrollbar */
.costumScrollbar::-webkit-scrollbar {
  width: 4px;
}

/* Scrollbar Track */
.costumScrollbar::-webkit-scrollbar-track {
  width: 4px;
  border-right: 10px solid #fff;
  border-left: 10px solid #fff;
}

/* Scrollbar Thumb */
.costumScrollbar::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  width: 4px;
  border-radius: 16px;
}

/* Scrollbar Track Pieces */
.costumScrollbar::-webkit-scrollbar-track-piece:start {
  background: #fff;
}

.costumScrollbar::-webkit-scrollbar-track-piece:end {
  background: #fff;
}


/* In your Tailwind CSS file or style tag */
.costumScrollbarHorizontal::-webkit-scrollbar:vertical {
  display: none;
}
/* Scrollbar */
.costumScrollbarHorizontal::-webkit-scrollbar {
  height: 4px;
}

/* Scrollbar Track */
.costumScrollbarHorizontal::-webkit-scrollbar-track {
  height: 4px;
  border-right: 10px solid #fff;
  border-left: 10px solid #fff;
}

/* Scrollbar Thumb */
.costumScrollbarHorizontal::-webkit-scrollbar-thumb {
  background-color: #e0e0e0;
  height: 4px;
  border-radius: 16px;
}

/* Scrollbar Track Pieces */
.costumScrollbarHorizontal::-webkit-scrollbar-track-piece:start {
  background: #fff;
}

.costumScrollbarHorizontal::-webkit-scrollbar-track-piece:end {
  background: #fff;
}

.green_bold_text {
  background: linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%);
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green-linear-text {
  background: linear-gradient(204.44deg, #01F91A -18.49%, #359B05 84.72%);
  font-weight: 700;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.orange-linear-text {
  background: linear-gradient(201deg, #F9F218 3.37%, #E2A246 86.36%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.blue-linear-text {
  background: var(--For-Icon-Blue, linear-gradient(204deg, #4590F4 -18.49%, #1361C9 84.72%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.red-linear-text {
  background: var(--For-Icon-Maroon, linear-gradient(204deg, #F90601 -18.49%, #8A1212 84.72%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.black-linear-text {
  background: var(--For-Icon-Black, linear-gradient(35deg, #151515 17.46%, #3D3D3D 58.62%, #595959 87.32%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-left {
  text-align: left !important;
}

.ql-align-right {
  text-align: right !important;
}

.ql-align-justify {
  text-align: justify !important;
}


.scroll-horizontal-hidden {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer/Edge */
}

.scroll-horizontal-hidden::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}