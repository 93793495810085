.container {
  @apply p-4 bg-[#F2F2F2] transition-all duration-500 rounded-xl mt-2;
}

.dashWrapper {
  @apply p-2 h-full rounded border border-dashed border-[#828282] overflow-hidden;
}

.wrapperSingle {
  @apply flex justify-center items-center;
}

.imageContainer {
  /* @apply max-w-[64px] min-w-[64px] max-h-[64px] min-h-[64px] mr-3 relative; */
}

.image {
  @apply w-full h-full object-cover rounded-lg;
}

.multiUploadContainer {
  @apply w-[64px] h-[64px] mr-3 border border-gray-200 rounded-lg border-dashed flex flex-col justify-center items-center;
}

.uploadContainer {
  @apply flex flex-col justify-center items-center;
}

.closeContainer {
  @apply absolute cursor-pointer right-1 top-1 w-4 h-4 bg-white rounded-full;
}

.error {
  @apply border border-red-500;
}

.errorMessage {
  @apply flex mt-2 items-center text-[#E82320];
}

/*
 *  STYLE SCROLLBAR
 */
.scrollStyle::-webkit-scrollbar-track {
  background-color: #e6e6e6;
}

.scrollStyle::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #4d4d4d;
}

.scrollStyle::-webkit-scrollbar-thumb {
  background-color: #c5c5c5;
  border-radius: 50px;
}
