@font-face {
  font-family: "GeneralSansRegular";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Regular.ttf)
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSansMedium";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Medium.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSansSemibold";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Semibold.ttf)
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "GeneralSansBold";
  src: url(./assets/fonts/GeneralSans/GeneralSans-Bold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}

//   @font-face {
//     font-family: "FuturaBQ";
//     src: url(./assets/fonts/Futura/FuturaBQ.otf) format("truetype");
//     font-weight: normal;
//     font-style: normal;
//   }

// Remove Scrollbar
body::-webkit-scrollbar,
main::-webkit-scrollbar,
section::-webkit-scrollbar {
  display: none;
}

body,
main {
  -ms-overflow-style: none;
}

a,
.ant-pagination .ant-pagination-item a {
  &:hover,
  &:active {
    color: unset;
    background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }
}

.link {
  color: unset;
  background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.divider {
  width: 100%;
  height: 1px;
  background: #e0e0e0;
}

// ======= LIST COLOR =======
.txt-green {
  background: linear-gradient(204.44deg, #01f91a -18.49%, #359b05 84.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.txt-yellow {
  background: linear-gradient(187.8deg, #f9f218 8.68%, #e2a246 282.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.txt-blue {
  background: linear-gradient(204.44deg, #4590f4 -18.49%, #1361c9 84.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.txt-yellow-soft {
  background: linear-gradient(187.8deg, #fffdc9 8.68%, #fff700 282.6%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.txt-maroon {
  background: linear-gradient(204.44deg, #f90601 -18.49%, #8a1212 84.72%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.txt-orange {
  background: linear-gradient(
    200.57deg,
    rgb(249, 242, 24) 3.37%,
    rgb(226, 162, 70) 86.36%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

// ======= END LIST COLOR =======

// ======= LIST BACKGROUND =======

.green-bg {
  background: linear-gradient(204.44deg, #01f91a -18.49%, #359b05 84.72%);
}

.red-bg {
  background: linear-gradient(180deg, #ea3634 0%, #94110f 100%);
}

.blue-bg {
  background: linear-gradient(204.44deg, #4590f4 -18.49%, #1361c9 84.72%);
}

.orange-bg {
  background: rgb(250, 171, 54);
  backdrop-filter: blur(8px);
}

.yellow-bg {
  background: linear-gradient(
    200.57deg,
    rgba(249, 242, 24, 0.32) 3.37%,
    rgba(226, 162, 70, 0.32) 86.36%
  );
}

.gold-dark-bg {
  background: linear-gradient(180deg, #f5e97c 0%, #e2a246 100%);
}

.whatsapp-brown-bg {
  background: linear-gradient(
    180deg,
    rgba(173, 122, 45, 0.15) 0%,
    rgba(153, 4, 0, 0.15) 100%
  );
}

.blue-transparent-bg {
  background: linear-gradient(
    204.44deg,
    rgba(69, 144, 244, 0.2) -18.49%,
    rgba(19, 97, 201, 0.2) 84.72%
  );
}

.yellow-dark {
  background: linear-gradient(
    200.57deg,
    rgba(249, 242, 24, 0.32) 3.37%,
    rgba(226, 162, 70, 0.32) 86.36%
  );
}
.drop-bg-50 {
  background: rgba(255, 255, 255, 0.5) !important;
}

.red-bg-shine {
  background-image: url("./assets/images/bg-red-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.blue-bg-shine {
  background-image: url("./assets/images/bg-blue-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.orange-bg-shine {
  background-image: url("./assets/images/bg-orange-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.dark-purple-bg-shine {
  background-image: url("./assets/images/bg-dark-purple-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.yellow-bg-shine {
  background-image: url("./assets/images/bg-yellow-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.soft-purple-bg-shine {
  background-image: url("./assets/images/bg-soft-purple-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.soft-green-bg-shine {
  background-image: url("./assets/images/bg-soft-green-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.purple-bg-shine {
  background-image: url("./assets/images/bg-purple-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.grey-bg-shine {
  background-image: url("./assets/images/bg-grey-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.dark-green-bg-shine {
  background-image: url("./assets/images/bg-dark-green-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.pink-bg-shine {
  background-image: url("./assets/images/bg-pink-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  // padding: 12px 24px;
}

.dark-grey-bg-shine {
  background-image: url("./assets/images/bg-dark-grey-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.bold-purple-bg-shine {
  background-image: url("./assets/images/bg-bold-purple-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.brown-bg-shine {
  background-image: url("./assets/images/bg-brown-shine.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

.soft-brown-bg-shine {
  background-image: url("./assets/images/bg-soft-brown.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 12px;
  padding: 12px 24px;
}

// ======= END LIST BACKGROUND =======

// ======= LIST BOX SHADOW =======
.soft-shadow {
  box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04),
    0px 4px 80px -4px rgba(0, 0, 0, 0.04);
}

.normal-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.06);
}

.hard-shadow {
  box-shadow: 0px 6px 64px -4px rgba(0, 0, 0, 0.04),
    0px 10px 16px rgba(0, 0, 0, 0.04), 0px 6px 32px rgba(0, 0, 0, 0.04);
}

.minim-shadow {
  box-shadow: 0px 2px 4px rgba(64, 72, 82, 0.1);
}

.highlight-shadow {
  box-shadow: 0px 8px 8px -4px rgba(0, 0, 0, 0.04),
    0px 18px 24px -10px rgba(0, 0, 0, 0.1),
    0px 4px 80px -4px rgba(0, 0, 0, 0.04);
}

// ======= END LIST BOX SHADOW =======

// ======= FORESHADOW =======

.foreshadow {
  background: linear-gradient(90deg, #000000 -21.76%, rgba(0, 0, 0, 0) 100%);
}

.backdrop {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(3px);
}

// CUSTOM ANT DESIGN
.ant-table-thead th {
  border-bottom: 1px solid #e0e0e0 !important;
  background-color: #fff !important;
  font-weight: 600 !important;
  font-size: 12px;
  line-height: 20px;
  color: #232323 !important;
}

.ant-table-thead th::before {
  width: 0px !important;
}

.ant-table-tbody td {
  font-size: 12px !important;
  color: #141414 !important;
}

.ant-table-selection-column {
  padding-inline-start: 16px !important;
}

.ant-checkbox:hover .ant-checkbox-inner {
  border: 1px solid #141414 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #141414 !important;
  border-color: #141414 !important;
}

.ant-checkbox-checked:after {
  border: 1px solid #141414 !important;
}

.ant-table-cell {
  border-bottom: none !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: transparent !important;
}

.ant-table-row-selected {
  background-color: #ff9c9a !important;
  &:hover .ant-table-cell {
    background-color: #ff9c9a !important;
  }
}

// ======= CUSTOM SELECT ======= //
.area-select {
  .ant-select-dropdown {
    padding: 0;
  }

  .ant-select-dropdown .ant-select-item {
    padding: 8px 16px;
    background: white;
    border-radius: 0px!important;
    &:hover {
      background: rgb(255, 220, 220);
      cursor: pointer;
    }
  }

  .ant-select-dropdown
    .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: rgb(255, 220, 220);

    &:hover {
      background: rgb(255, 220, 220);
    }
  }
}
