.marketing-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    gap: 346px;
    background: #FFFFFF;
    border-bottom: 1px solid #E0E0E0;
    border-radius: 20px 20px 0px 0px;
}

.marketing-cards-container {
    width: 100%;
    height: 355px;
    background: #FFFFFF;
    box-shadow: 0px 10px 16px -6px rgba(0, 0, 0, 0.04), 0px 4px 80px -4px rgba(0, 0, 0, 0.04);
    border-radius: 16px;
}

.marketing-image-kabah {
    object-fit: cover;
    width: 100%;
    height: 156px;
    border-radius: 16px 16px 0px 0px;
}