#toolbar {
	padding: 16px;
	background: #ffffff;
	border: 1px solid rgba(64, 72, 82, 0.1);
	border-radius: 8px 8px 0px 0px;
	width: 100%;
}

.ql-emoji {
	float: right !important;
}

.ql-bold,
.ql-italic,
.ql-underline,
.ql-strike,
.ql-color,
.ql-background,
.ql-emoji,
.ql-link,
.ql-list,
.ql-bullet,
.ql-align {
	margin: 5px;
	border: 1px solid rgba(64, 72, 82, 0.1) !important;
	border-radius: 4px;
	width: 32px;
	height: 32px;
	text-align: center;
}
